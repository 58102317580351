<template>
  <div>
    <!-- pic92A0232A40B5653CBA63FBF0B78698D9.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/challenge-more-minifigs.jpg">

    <p>
      The LEGO Universe News Network has just received this urgent message from Professor Brickkeeper:
    </p>
    <p>
      <i>Earth Friends,</i>
    </p>
    <p>
      <i>I can only hope my transmission reaches you safely!</i>
    </p>
    <p>
      <i>I need your creativity to help accomplish the <router-link to="/help-the-minifigs">Great Minifig Mission</router-link>! Overcome the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: 'c8f3fb2d-9b0b-4a4c-85e5-f30515314dbe'} }"></router-link> to send more minifigs to my Universe!</i>
    </p>
    <p>
      <i>You will be asked to start mastering the power of Pure Imagination--and FAST! The Maelstrom grows stronger each day! I call upon every available minifig to join in the battle to save imagination!</i>
    </p>
  </div>
</template>
